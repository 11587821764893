import React from 'react'
import { Link } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Banner from '../components/Banner'

// alternate image component
// import Image from "../components/image"
import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'
import pic05 from '../assets/images/pic05.jpg'
import pic06 from '../assets/images/pic06.jpg'

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Home" />

        <Banner />

        {/* <div id="main">
          <section id="one" className="tiles">
            <article style={{ backgroundImage: `url(${pic02})` }}>
              <header className="major">
                <h3>Anki — Cozmo is Lost in Reddit</h3>
                <p>feugiat amet tempus</p>
              </header>
              <Link to="/landing" className="link primary"></Link>
            </article>

            <article style={{ backgroundImage: `url(${pic01})` }}>
              <header className="major">
                <h3>W+K Amsterdam - SOS Necessary Explosion</h3>
                <p>AR Music App</p>
              </header>
              <Link to="/landing" className="link primary"></Link>
            </article>

            <article style={{ backgroundImage: `url(${pic03})` }}>
              <header className="major">
                <h3>KFC — The Hard Way</h3>
                <p>Escape room VR Game</p>
              </header>
              <Link to="/landing" className="link primary"></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic04})` }}>
              <header className="major">
                <h3>KFC — H.A.R.L.A.N.D.</h3>
                <p>Nisl sed aliquam</p>
              </header>
              <Link to="/landing" className="link primary"></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic05})` }}>
              <header className="major">
                <h3>Red Fang — Headbang</h3>
                <p>Ipsum dolor sit amet</p>
              </header>
              <Link to="/landing" className="link primary"></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic06})` }}>
              <header className="major">
                <h3>W+K — Needybot</h3>
                <p>Feugiat amet tempus</p>
              </header>
              <Link to="/landing" className="link primary"></Link>
            </article>
          </section>
          <section id="two">
            <div className="inner">
              <header className="major">
                <h2>Massa libero</h2>
              </header>
              <p>
                Nullam et orci eu lorem consequat tincidunt vivamus et sagittis
                libero. Mauris aliquet magna magna sed nunc rhoncus pharetra.
                Pellentesque condimentum sem. In efficitur ligula tate urna.
                Maecenas laoreet massa vel lacinia pellentesque lorem ipsum
                dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et
                sagittis libero. Mauris aliquet magna magna sed nunc rhoncus
                amet pharetra et feugiat tempus.
              </p>
              <ul className="actions">
                <li>
                  <Link to="/landing" className="button next">
                    Get Started
                  </Link>
                </li>
              </ul>
            </div>
          </section>
        </div> */}
      </Layout>
    )
  }
}

export default HomeIndex
